import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Balance Practice 4-4-4-4 `}<em parentName="p">{`(light weight, focus on speed under the
bar)`}</em></p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Power Snatch`}</p>
    <p>{`1-Hang Power Snatch`}</p>
    <p>{`1-Hang Squat Snatch`}</p>
    <p>{`1-Full Snatch`}</p>
    <p><em parentName="p">{`*`}{`using 70% 1rm of your weakest movement`}</em></p>
    <p>{`then, at minute 12:00`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`15-Bar Facing Burpees`}</p>
    <p>{`15-OHS (95/65`}{`#`}{`)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Wednesday, July 4th, we will have 1 class at 9:00am that is free
for everyone so bring a friend!  Open gym will follow from 10am-1pm but
the 9:00am class is the only class for the day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      